import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"

import './layout.css?v=005'
import './theme_soccer.css?v=001'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
      <>
        <Helmet title={data.site.siteMetadata.title} >
          <html lang="en" />
          <body className="soccer" />
        </Helmet>        
        
        {children}
      </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout